import React from "react";
import { Link } from "react-router-dom";

const pageNotFound = () => {
  return (
    <section>
      <div className="container vh-100">
        <div className="page-not-found-wrapper">
          <div className="my-5 text-center">
            <h1 className="lg-text-3 text-danger">Oh Snap!</h1>
            <h4 className="">404 - Page Not Found</h4>
            <p className="mx-5">
              The page you are looking for was removed, renamed or might never
              existed.
            </p>
            <Link to="/">
              <button className="acts-active acts-btn">Go back home</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default pageNotFound;
