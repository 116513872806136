import React from "react";
import AboutImg from "../images/about.png";
import { Link } from "react-router-dom";

export default function AboutUs() {
  return (
    <section className="bg-blue">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-6 col-sm-12 col-12 my-5">
            <img className="img-fluid" alt="about-img" src={AboutImg} />
          </div>
          <div className="col-lg-7 col-md-6 col-sm-12 col-12 my-5 d-flex flex-column justify-content-center">
            <h3 className="text-white">About Us</h3>
            <p className="text-white font-weight-100">
              Lorem Ipsum is simply dummy text of the printing and typese tting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and
            </p>
            <p className="text-white font-weight-100">
              Lorem Ipsum is simply dummy text of the printing and typese tting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and
            </p>
            <Link to="/about">
              <span className="text-white">See More</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
