import React from "react";
import footerLogo from "../images/footer-logo.png";
import playStore from "../images/play-store.png";
import appStore from "../images/app-store.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  return (
    <section className="bg-blue">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12 col-12 mrt-mrb-3">
            <a href="/">
              <img
                className="img-fluid"
                alt="footer-logo"
                src={footerLogo}
                width="170px"
              />
            </a>
            <p className="text-white mb-0">Address:</p>
            <p className="text-white">Surat - 395002, Gujarat, India</p>
            <p className="text-white mb-0">Phone:</p>
            <p className="text-white">+91 123456789</p>
            <p className="text-white">
              {/* Copyright © K2 helplaw... All right reserved. */}
              Developed by
              <b onClick={() => window.open('https://logicalloop.com/')} target="_blank" style={{ cursor: "pointer" }}> Logical Loop</b>
            </p>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 col-12 mrt-mrb-3">
            <div className="row mb-3">
              <div className="col-6">
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/your-bookmark">Bookmark</a>
                  </li>
                  <li>
                    <a href="/laws">Acts</a>
                  </li>
                  <li>
                    <a href="/forms">Form</a>
                  </li>
                </ul>
              </div>
              {/* <div className="col-6">
                <ul>
                  <li>
                    <a href="/">Feedback</a>
                  </li>
                  <li>
                    <a href="/">Language</a>
                  </li>
                  <li>
                    <a href="/">Help & Chat</a>
                  </li>
                </ul>
              </div> */}
            </div>
            <div className="mrt-3">
              <ul className="social-group">
                <li className="social-items">
                  <a href="/">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                </li>
                <li className="social-items">
                  <a href="/">
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                </li>
                <li className="social-items">
                  <a href="/">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                </li>
                <li className="social-items">
                  <a href="/">
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                </li>
              </ul>
              <p className="text-white">support@k2helplaw.com</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 col-12 mrt-mrb-3 d-flex justify-content-between">
            <div className="row">
              <div className="col-12 ">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control contact-field"
                    placeholder="Email Address..."
                  />
                  <div className="input-group-append">
                    <button className="btn btn-white" type="button">
                      Send
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-12 mrt-5 t-center">
                <p className="text-white my-3 mx-4">
                  Try it now on your phone or tablet.
                </p>
                <ul className="brand-group">
                  <li className="brand-items">
                    <a className="footer-links" href="/">
                      <img
                        className="img-fluid"
                        alt="app-store-img"
                        src={appStore}
                      />
                    </a>
                  </li>
                  <li className="brand-items">
                    <a className="footer-links" href="/">
                      <img
                        className="img-fluid"
                        alt="play-store-img"
                        src={playStore}
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
