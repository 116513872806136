import React, { useCallback, useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Border from "../components/Border";
import BigCard from "../components/BigCard";
import ActBtn from "../components/ActBtn";
import { Link } from "react-router-dom";
import heroJudgements from "../images/hero-images/judgements.png";
// import judgementsData from "../json/judgementsData.json";
import { useParams } from "react-router-dom";
import axios from "axios";
import apis from "../config/apis";
import headers from "../config/header";

const Judgements = () => {
  const id = useParams();
  const [data, setData] = useState([]);

  const fatchJudgements = useCallback(() => {
    const body = {
      _id: id.id,
    };
    // console.log(id);
    axios
      .post(apis + "admin/findJudgementByLawId", body, headers)
      .then((res) => {
        setData(res?.data?.data);
        console.log(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    fatchJudgements();
  }, [fatchJudgements]);

  return (
    <div>
      <Navbar />

      <Hero value="Judgements" img={heroJudgements} />

      <Border />

      <section>
        <div className="container">
          <ActBtn id={id} />
        </div>
      </section>

      <section>
        <div className="container my-3">
          <div className="row">
            {data.map((judgements) => {
              return (
                <div
                  className="col-lg-12 col-md-12 col-sm-12 col-12 my-2"
                  key={judgements._id}
                >
                  <Link
                    to={`/judgements-details/${judgements._id}`}
                    style={{
                      textDecoration: `none`,
                      color: `#000000`,
                      cursor: `pointer`,
                    }}
                  >
                    <BigCard
                      id={judgements._id}
                      heading={judgements.en.judgementYear}
                      title={judgements.en.judgementTitle}
                    />
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Judgements;
