import React from "react";
import lawsDetailsBackground from "../images/laws-details-background-1.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { WhatsappShareButton } from "react-share";
import { webUrl } from "../config/apis";

const LawDetailsCard = ({ lawNumber, title, id }) => {
  // const {
  //   WhatsappShareButton,
  //   TwitterShareButton,
  //   EmailShareButton
  // } = ShareButtons;
  return (
    <>
      <div
        className="card border-0 card-shadow border-20"
        style={{
          backgroundImage: `url(${lawsDetailsBackground})`,
          backgroundSize: `cover`,
          height: `auto`,
        }}
      >
        <div className="card-body p-24">
          <div className="judgements-card">
            <h4 className="card-title font-weight-600 mb-0 ml-3 text-center">
              {lawNumber}
            </h4>
            <div className="mx-4">
              <div className="vertical-line-1"></div>
              <div className="vertical-line-2"></div>
            </div>
            <h5 className="mb-0">{title}</h5>
          </div>
          <div className="share-sm-icon">
            <WhatsappShareButton
              url={`${webUrl}${id}`}
            >
              <FontAwesomeIcon icon={faShareAlt} />
            </WhatsappShareButton>
          </div>
          {/* <div className="bookmark-sm-icon">
            <FontAwesomeIcon icon={faBookmark} />
          </div> */}
        </div>
      </div>
    </>
  )
}

export default LawDetailsCard;
