import React from "react";
import homeLabel from "../images/home-label.png";
import backgroundImg from "../images/laws-bg.png";

export default function Label({ Title, Image }) {
  return (
    <section
      className="bg-blue"
      style={{
        height: `auto`,
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: `cover`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-6 col-sm-12 col-12 my-5">
            <img
              className="img-fluid"
              alt="label-img"
              src={Image}
              height="400px"
              width="400px"
            />
          </div>
          <div className="col-lg-7 col-md-6 col-sm-12 col-12 my-5 mt-auto">
            <h1 className="lg-text-4 text-uppercase text-center text-white font-weight-700 align-text-bottom">
              {Title}
            </h1>
            <hr className="bg-white mx-5" />
          </div>
        </div>
      </div>
    </section>
  );
}
