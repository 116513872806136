import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Border from "../components/Border";
import PrimaryHero from "../components/PrimaryHero";
import heroAbout from "../images/hero-images/about-us.png";
import AboutImgOne from "../images/about.png";
import AboutImgTwo from "../images/about-img-2.png";
import AboutLabelBackground from "../images/about-label-background.jpg";

export default function About() {
  return (
    <>
      <Navbar />

      <PrimaryHero title="About Us" heroImg={heroAbout} />

      <Border />

      <section>
        <div className="container">
          <div className="row my-5">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <img
                className="img-fluid"
                alt="about-img-1"
                src={AboutImgOne}
                width="450px"
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex flex-column justify-content-center">
              <h1>
                Lorem lpsum is simply dummy text of the printing industry.
              </h1>
              <p>
                Once we know exactly what you are looking for and understand a
                bit more about your business, our research team will get to work
                on finding out exactly how your target audience uses digital
                platforms to influence their purchase decisions.
              </p>
              <p>
                Once we know exactly what you are looking for and understand a
                bit more about your business,
              </p>
            </div>
          </div>
          <div className="row my-5">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex flex-column justify-content-center">
              <p>
                Once we know exactly what you are looking for and understand a
                bit more about your business, our research team will get to work
                on finding out exactly how your target audience uses digital
                platforms to influence their purchase decisions. Once we know
                exactly what you are looking for and understand a bit more about
                your business, our research team will get to work on finding out
                exactly how your target audience uses digital platforms to
                influence their purchase decisions.
              </p>
              <p>
                Once we know exactly what you are looking for and understand a
                bit more about your business, our research team will get to work
                on finding out exactly how your target audience uses digital
                platforms to influence their purchase decisions. Once we know
                exactly what you are looking for and understand a bit more about
                your business, our research team will get to work on finding out
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <img
                className="img-fluid"
                alt="about-img-1"
                src={AboutImgTwo}
                width="450px"
              />
            </div>
          </div>
        </div>
      </section>

      <section
        className="about-label"
        style={{
          backgroundImage: `url(${AboutLabelBackground})`,
          backgroundSize: `cover`,
        }}
      >
        <div className="container p-5">
          <h1 className="text-white text-center font-weight-600 mb-3">
            Lorem lpsum is simply dummy text
          </h1>
          <p className="text-white text-center">
            Once we know exactly what you are looking for and understand a bit
            more about your business, our research team will get to work on
            finding out exactly how your target audience uses digital platforms
            to influence their purchase decisions.
          </p>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row my-5">
            <div className="col-lg-1 col-md-1 col-sm-12 col-12">
              <h3 className="text-center mb-0 font-weight-600">01</h3>
              <div className="d-flex justify-content-center">
                <div className="about-vertical-line"></div>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-12 col-12">
              <h2>Confidential work</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum is simply dummy text of the printing and
                typesetting industry.
              </p>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-12 col-12">
              <h3 className="text-center mb-0 font-weight-600">04</h3>
              <div className="d-flex justify-content-center">
                <div className="about-vertical-line"></div>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-12 col-12">
              <h2>High excellency</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum is simply dummy text of the printing and
                typesetting industry.
              </p>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-12 col-12">
              <h3 className="text-center mb-0 font-weight-600">02</h3>
              <div className="d-flex justify-content-center">
                <div className="about-vertical-line"></div>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-12 col-12">
              <h2>Individual approach</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum is simply dummy text of the printing and
                typesetting industry.
              </p>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-12 col-12">
              <h3 className="text-center mb-0 font-weight-600">05</h3>
              <div className="d-flex justify-content-center">
                <div className="about-vertical-line"></div>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-12 col-12">
              <h2>Help sincerely</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum is simply dummy text of the printing and
                typesetting industry.
              </p>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-12 col-12">
              <h3 className="text-center mb-0 font-weight-600">03</h3>
              <div className="d-flex justify-content-center">
                <div className="about-vertical-line"></div>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-12 col-12">
              <h2>Parallelogram</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum is simply dummy text of the printing and
                typesetting industry.
              </p>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-12 col-12">
              <h3 className="text-center mb-0 font-weight-600">06</h3>
              <div className="d-flex justify-content-center">
                <div className="about-vertical-line"></div>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-12 col-12">
              <h2>Go through</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum is simply dummy text of the printing and
                typesetting industry.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
