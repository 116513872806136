import React from "react";

const Blogs = ({ img, title, description }) => (
  <div className="card card-shadow border-20">
    <img
      className="card-img-top blog_img"
      src={img}
      alt="blog-img"
      style={{ borderRadius: `20px 20px 0 0` }}
    />
    <div className="card-body">
      <h5
        className="card-title"
        style={{
          display: `-webkit-box`,
          WebkitLineClamp: `1`,
          WebkitBoxOrient: `vertical`,
          overflow: `hidden`,
        }}
      >
        {title}
      </h5>
      <p
        className="card-text text-grey opacity-5"
        style={{
          display: `-webkit-box`,
          WebkitLineClamp: `5`,
          WebkitBoxOrient: `vertical`,
          overflow: `hidden`,
        }}
      >
        {description}
      </p>
    </div>
  </div>
);

export default Blogs;
