import React, { useCallback, useEffect, useState } from "react";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Border from "../components/Border";
import SmallCard from "../components/SmallCard";
import heroTaxAct from "../images/hero-images/tax-act-sections.png";
import CardInShort from "../components/CardInShort";
import ActBtn from "../components/ActBtn";
import { useParams } from "react-router-dom";
import axios from "axios";
import apis from "../config/apis";
import headers from "../config/header";

const initial = [
  {
    laws: {
      en: {
        lawSummary: "",
      },
      ministry: {
        ministryName: "",
      },
    },
  },
];

export default function Acts() {
  const printButtonLabel = (event) => {
    console.log(event.target.name);
  };

  const id = useParams();
  const [data, setData] = useState([]);
  const [laws, setLaws] = useState(initial);

  const fatchLaws = useCallback(() => {
    const body = {
      _id: id.id,
    };
    axios
      .post(apis + "admin/findSectionByLawId", body, headers)
      .then((res) => {
        setData(res?.data?.data);
        console.log(res?.data?.data, 'law inside section');
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const fatchLawsDetails = useCallback(() => {
    const body = {
      _id: id.id,
    };
    axios
      .post(apis + "admin/findLawById", body, headers)
      .then((res) => {
        setLaws(res?.data?.data);

      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    fatchLaws();
    fatchLawsDetails();
  }, [fatchLaws, fatchLawsDetails]);

  return (
    <div>
      <Navbar />

      <Hero value="Tax Act" img={heroTaxAct} />

      <Border />

      <section>
        <div className="container">
          <ActBtn
            actBtn="Details"
            id={id}
            doSomethingAfterClick={printButtonLabel}
          />
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-4 mb-2">
              <CardInShort
                actNumber={laws[0].laws.en.lawNumber}
                actInShortDescription={laws[0].laws.en.lawTitle}
                id={laws[0].laws._id}
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row my-4">
            {data.sort((a, b) => ((a?.sectionNumberId) > (b?.sectionNumberId || b?.sectionNumberId)) ? 1 : -1).map((acts) => {
              return (
                <div
                  className="col-lg-3 col-md-6 col-sm-12 col-12 my-2"
                  key={acts._id}
                >
                  <SmallCard
                    heading={acts?.en?.sectionNumber || acts?.gj?.sectionNumber || acts?.hi?.sectionNumber || acts?.mh?.sectionNumber}
                    title={acts?.en?.sectionTitle || 'Comming Soon'}
                    id={`/laws-details/${acts._id}`}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
