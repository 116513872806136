import React, { useCallback, useEffect, useState } from "react";
import { Markup } from "interweave";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import cardBackground from "../images/judgements-bg-1.png";
import axios from "axios";
import apis from "../config/apis";

const FormSlider = () => {
    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 3,
        speed: 500,
        rows: 1,
        slidesPerRow: 2,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    slidesPerRow: 1,
                    centerMode: true,
                    infinite: true,
                    centerPadding: "60px",
                    speed: 500,
                    className: "center",
                    arrows: false,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    slidesPerRow: 1,
                    centerMode: true,
                    infinite: true,
                    centerPadding: "60px",
                    speed: 500,
                    className: "center",
                    arrows: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    slidesPerRow: 1,
                    centerMode: true,
                    infinite: true,
                    centerPadding: "60px",
                    speed: 500,
                    className: "center",
                    arrows: false,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    slidesPerRow: 1,
                    centerMode: true,
                    infinite: true,
                    centerPadding: "60px",
                    speed: 500,
                    className: "center",
                    arrows: false,
                },
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    slidesPerRow: 1,
                    centerMode: true,
                    infinite: true,
                    centerPadding: "60px",
                    speed: 500,
                    className: "center",
                    arrows: false,
                },
            },
        ],
    };

    const [data, setData] = useState([]);

    const fatchForm = useCallback(() => {
        axios
            .get(apis + "admin/getTypesOfForm")
            .then((res) => {
                setData(res?.data?.data);
                console.log(res?.data?.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        fatchForm();
    }, [fatchForm]);

    return (
        <>
            <Slider {...settings}>
                {data.slice(0, 1).map((form) => {
                    return (
                        <div className="card-wrapper" key={form._id}>
                            <div
                                className="card border-0 card-shadow border-20"
                                style={{
                                    backgroundImage: `url(${cardBackground})`,
                                    backgroundSize: `cover`,
                                    height: `auto`,
                                    width: `300px`,
                                }}
                            >
                                <div className="card-body p-75" id={form._id}>
                                    <Link
                                        to={`/form-details/${form._id}`}
                                        style={{
                                            textDecoration: `none`,
                                            color: `#000000`,
                                            cursor: `pointer`,
                                        }}
                                    >
                                        <h5
                                            className="card-title font-weight-600"
                                            style={{
                                                display: `-webkit-box`,
                                                WebkitLineClamp: `1`,
                                                WebkitBoxOrient: `vertical`,
                                                overflow: `hidden`,
                                            }}
                                        >
                                            {form.FormName}
                                        </h5>
                                    </Link>
                                    <div className="color-group mt-4 mb-3">
                                        <div className="color-item-1"></div>
                                        <div className="color-item-2"></div>
                                    </div>
                                    <div
                                        className="card-text font-weight-400"
                                        style={{
                                            display: `-webkit-box`,
                                            WebkitLineClamp: `3`,
                                            WebkitBoxOrient: `vertical`,
                                            overflow: `hidden`,
                                        }}
                                    >
                                        <Markup content={form.FormDescription} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Slider>
        </>
    );
};

export default FormSlider;
