import React, { useCallback, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import EnglishHeroImg from "../images/hero-images/emergency.png";
import PrimaryHero from "../components/PrimaryHero";
import Border from "../components/Border";
import axios from "axios";
import apis from "../config/apis";
import headers from "../config/header";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';



export default function Emergency() {
    const [formData, setFormData] = useState([]);
    const [formList, setFormList] = useState([]);
    const [IsEnglish, setIsEnglish] = useState(true)
    const [IsHindi, setIsHindi] = useState(false)
    const [IsGujarati, setIsGujarati] = useState(false)
    const [IsMarathi, setMarathi] = useState(false)
    const [dropdownOpen, setOpen] = useState(false);

    const toggle = () => setOpen(!dropdownOpen);



    const fatchFormData = useCallback(() => {
        axios
            .post(apis + "admin/findTypesOfFormById", headers)
            .then((res) => {
                setFormData(res?.data?.data);
                console.log(res?.data?.data, 'form data');
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);


    const onEnglish = (e) => {
        if (e.target.checked) {
            setIsEnglish(true)
            setIsHindi(false)
            setIsGujarati(false)
            setMarathi(false)
        } else {
            setIsEnglish(false)
        }
    }

    const onHindi = (e) => {
        if (e.target.checked) {
            setIsHindi(true)
            setIsEnglish(false)
            setIsGujarati(false)
            setMarathi(false)
        } else {
            setIsHindi(false)
        }
    }

    const onGujarati = (e) => {
        if (e.target.checked) {
            setIsGujarati(true)
            setIsEnglish(false)
            setIsHindi(false)
            setMarathi(false)
        } else {
            setIsGujarati(false)
        }
    }

    const onMarathi = (e) => {
        if (e.target.checked) {
            setMarathi(true)
            setIsEnglish(false)
            setIsHindi(false)
            setIsGujarati(false)
        } else {
            setMarathi(false)
        }
    }


    useEffect(() => {
        fatchFormData();
    }, [fatchFormData]);
    return (
        <>
            <Navbar />
            <PrimaryHero title="Emergency" paragraph="Lorem Ipsum is simply dummy text of the printing industry." heroImg={EnglishHeroImg} />
            <Border />
            <section className="emergency">
                <div className="container">
                    <div className="form_filters">
                        <div className="search_box">
                            <Autocomplete
                                // options={searchData}
                                onChange={(ev, selectedProduct) => console.log(selectedProduct)}
                                getOptionLabel={(option) => option.laws.en.lawTitle}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Search Acts"
                                        className="search-input-header"
                                        style={{ borderRadius: `50px` }}
                                        // disableClearable
                                        margin="normal"
                                        variant="outlined"
                                        InputProps={{ ...params.InputProps, type: "search" }}
                                    // onChange={(e) => fatchSearchData(e.target.value)}
                                    />
                                )}
                            />
                        </div>
                        <div className="state_drop">
                            <select name="state" id="state">
                                <option value="gujarat">gujarat</option>
                                <option value="mumbai">mumbai</option>
                                <option value="delhi">delhi</option>
                            </select>
                        </div>
                        <div className="city_drop">
                            <select name="city" id="city">
                                <option value="surat">surat</option>
                                <option value="ahmedabad">ahmedabad</option>
                                <option value="vadodara">vadodara</option>
                            </select>
                        </div>
                        <div className="form_drop">
                            <select name="lang" id="lang">
                                <option value="english">english</option>
                                <option value="hindi">hindi</option>
                                <option value="gujarati">gujarati</option>
                                <option value="marathi">marathi</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}
