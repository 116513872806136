import React, { useCallback, useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Border from "../components/Border";
import ActBtn from "../components/ActBtn";
import ActsDetailsBtn from "../components/ActsDetailsBtn";
import heroTaxActDetails from "../images/hero-images/tax-act-details.png";
import ActDetailsCard from "../components/ActDetailsCard";
import { Markup } from "interweave";
import { useParams } from "react-router-dom";
import axios from "axios";
import apis from "../config/apis";
import headers from "../config/header";

const initial = [
  {
    laws: {
      en: {
        lawSummary: "",
      },
      ministry: {
        ministryName: "",
      },
    },
  },
];

const ActsDetails = () => {
  const printButtonLabel = (event) => {
    console.log(event.target.name);
  };

  const id = useParams();
  const [data, setData] = useState(initial);
  console.log(id);
  const fatchLaws = useCallback(() => {
    const body = {
      _id: id.id,
    };
    axios
      .post(apis + "admin/findLawById", body, headers)
      .then((res) => {
        setData(res?.data?.data);
        console.log(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const btnTitle = "Act No: " + data[0].laws.en.lawNumber;

  useEffect(() => {
    fatchLaws();
  }, [fatchLaws]);

  return (
    <>
      <Navbar />

      <Hero value="Tax Act" img={heroTaxActDetails} />

      <Border />

      <section>
        <div className="container">
          <ActBtn
            actBtn="Details"
            id={id}
            doSomethingAfterClick={printButtonLabel}
          />
        </div>
      </section>

      <section>
        <div className="container my-4">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-6 col-sm-12 col-12 my-2">
              <div className="acts-details-box border-20 text-center">
                <h5 className="my-3">Act Year {data[0].laws.en.lawYear}</h5>
                <div className="hr-3"></div>
                <h5 className="my-3">Chapters {data[0].chapterCount}</h5>
                <div className="hr-3"></div>
                <h5 className="my-3">Sections {data[0].sectionCount}</h5>
              </div>
            </div>

            <div className="col-lg-8 col-md-6 col-sm-12 col-12 my-2">
              <ActsDetailsBtn btnDetails={btnTitle} />
              <h4 className="font-weight-500 my-2">
                {data[0].laws.en.lawTitle}
              </h4>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <header>
            <h4 className="font-weight-600">Summary</h4>
          </header>
          <div className="font-weight-400">
            <Markup content={data[0].laws.en.lawSummary} />
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row mb-4">
            <div className="col-lg-3 col-md-6 col-sm-12 col-12 my-2">
              <ActDetailsCard
                title="Enactment Date"
                subtitle={data[0].laws.enactmentDate}
              />
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 col-12 my-2">
              <ActDetailsCard
                title="Commencement Date"
                subtitle={data[0].laws.commencementDate}
              />
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 col-12 my-2">
              <ActDetailsCard
                title="Ministry"
                subtitle={data[0].laws.ministry.ministryName}
              />
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 col-12 my-2">
              <ActDetailsCard
                title="Department"
                subtitle={data[0].laws.department}
              />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ActsDetails;
