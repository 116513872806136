import React from "react";
import { useHistory } from "react-router";

const CardInShort = ({ actNumber, actInShortDescription, id }) => {
  const history = useHistory();

  return (
    <div
      className="card border-0 card-shadow border-15 bg-blue"
      onClick={() => history.push(`/acts-details/${id}`)}
      style={{ cursor: `pointer` }}
    >
      <div className="card-body p-75">
        <div className="judgements-card">
          <h4 className="card-title font-weight-600 mb-0 ml-3 text-white text-center font-h4">
            {actNumber}
          </h4>
          <div className="mx-4">
            <div className="vertical-line-1"></div>
            <div className="vertical-line-2"></div>
          </div>
          <p className="mb-0 text-white font-xsm" style={{ fontSize: `18px` }}>
            {actInShortDescription}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CardInShort;
