import React from "react";

const ActDetailsCard = ({ title, subtitle }) => (
  <div className="card border-0 card-shadow border-20">
    <div className="card-body p1">
      <h5 className="card-title font-weight-600">{title}</h5>
      <div className="color-group my-3">
        <div className="color-item-lg-1"></div>
        <div className="color-item-lg-2"></div>
      </div>
      <p className="mb-0">{subtitle}</p>
    </div>
  </div>
);

export default ActDetailsCard;
