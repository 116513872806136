// const api = "http://192.168.29.136:3080/api/";
// const api = "https://k2law.herokuapp.com/api/";

// DigitalOcean
// const api = "http://143.244.133.216:3080/api/";

//localhost
// const api = "http://localhost:3080/api/";

// AWS APT
const api = "https://api.k2helplaw.in/api/"
export default api;


// for share pup
export const webUrl = "https://k2helplaw.in/laws-details/"