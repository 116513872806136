import React from "react";
import actsBackground from "../images/acts-bg-1.png";
import { useHistory } from "react-router";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBookmark } from '@fortawesome/free-solid-svg-icons';

const SmallCard = ({ heading, title, Description, id }) => {
  const history = useHistory();

  return (
    <div
      className="card border-0 card-shadow border-20"
      onClick={() => history.push(id)}
      style={{
        backgroundImage: `url(${actsBackground})`,
        backgroundSize: `cover`,
        height: `auto`,
        cursor: `pointer`,
      }}
    >
      <div className="card-body p1">
        <h5 className="card-title font-weight-600">{heading}</h5>
        <div className="color-group mt3">
          <div className="color-item-1"></div>
          <div className="color-item-2"></div>
        </div>
        <h5
          className="mt-3 mb-2"
          style={{
            display: `-webkit-box`,
            WebkitLineClamp: `2`,
            WebkitBoxOrient: `vertical`,
            overflow: `hidden`,
          }}
        >
          {title}
        </h5>
        {/* <div className="bookmark-sm-icon">
                <FontAwesomeIcon icon={faBookmark} />
            </div> */}
      </div>
    </div>
  );
};

export default SmallCard;
