import React, { useContext } from "react";
import WelcomeLogo from "../images/welcome-logo.png";
import WelcomeImg from "../images/welcome-bg.png";
import WelcomeBackground from "../images/welcome-background.png";
import { useHistory } from "react-router-dom";
import { Context } from "../data/context";

const WelcomePage = () => {
  const history = useHistory();
  const { setLanguage } = useContext(Context);

  return (
    <section>
      <div
        className="container welcome-wrapper bg-blue"
        style={{
          backgroundImage: `url(${WelcomeBackground})`,
          backgroundSize: `cover`,
          height: `auto`,
          backgroundRepeat: `no-repeat`,
          borderRadius: `20px`,
        }}
      >
        <div className="row welcome-items">
          <div className="col-lg-4">
            <img
              className="img-fluid"
              alt="welcome-logo"
              src={WelcomeLogo}
              width="150px"
            />
            <h3 className="text-white">Welcome!</h3>
            <p className="text-white">
              Welcome to <b>K2helplaw</b>
            </p>
            <hr style={{ background: `#fff` }} />
            <p className="text-white">Please select your language</p>
            <form>
              <div className="form-group">
                <select
                  className="form-control welcome-form"
                  id="exampleFormControlSelect1"
                  onChange={(e) => setLanguage(e.target.value)}
                >
                  <option selected value="english">
                    English
                  </option>
                  <option value="hindi">Hindi</option>
                  <option value="gujarati">Gujarati</option>
                  <option value="marathi">Marathi</option>
                </select>
              </div>
              <button
                type="button"
                className="welcome-btn"
                onClick={() => history.push("/complete-profile")}
              >
                Next
              </button>
            </form>
          </div>
          <div
            className="col-lg-8"
            style={{ display: `flex`, justifyContent: `center` }}
          >
            <img
              className="img-fluid"
              alt="welcome-img"
              src={WelcomeImg}
              width="500px"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WelcomePage;
