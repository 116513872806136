import React, { useContext, useState } from "react";
import WelcomeLogo from "../images/welcome-logo.png";
import WelcomeImg from "../images/welcome-bg.png";
import WelcomeBackground from "../images/welcome-background.png";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import apis from "../config/apis";
import axios from "axios";
import headers from "../config/header";
import { Context } from "../data/context";

const OTP = () => {
  const history = useHistory();
  const { mobile } = useContext(Context);
  const [otp, setOtp] = useState(0);

  const onNext = () => {
    const body = {
      phoneNo: mobile,
      otp: parseInt(otp)
    };
    axios
      .post(apis + "user/checkOTP", body, headers)
      .then((res) => {
        if (res.data.message === "OTP is verified") {
          history.push("/welcome");
        }
        if (res.data.message === "User is verified") {
          localStorage.setItem("k2Laws", JSON.stringify(res.data.data));
          history.push("/");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <section>
      <div
        className="container welcome-wrapper bg-blue"
        style={{
          backgroundImage: `url(${WelcomeBackground})`,
          backgroundSize: `cover`,
          height: `auto`,
          backgroundRepeat: `no-repeat`,
          borderRadius: `20px`,
        }}
      >
        <div className="row welcome-items">
          <div className="col-lg-4">
            <img
              className="img-fluid"
              alt="welcome-logo"
              src={WelcomeLogo}
              width="150px"
            />
            <h3 className="text-white">Sign Up</h3>
            <p className="text-white" style={{ fontSize: `14px` }}>
              Sign Up by entering your credentials below
            </p>
            <hr style={{ background: `#fff` }} />
            <p className="text-white">Enter your mobile number</p>
            <form>
              <div className="form-group">
                <OtpInput
                  className="otp-input"
                  // onChange={(otp) => console.log(otp)}
                  numInputs={4}
                  isInputSecure={true}
                  separator={<span>-</span>}
                  value={otp}
                  onChange={(otp) => setOtp(otp)}
                />
                <Link to="/">
                  <p className="text-white mt-2 mb-0">Resend OTP</p>
                </Link>
              </div>
              <button
                type="button"
                className="welcome-btn"
                onClick={() => onNext()}
              >
                Next
              </button>
            </form>
          </div>
          <div
            className="col-lg-8"
            style={{ display: `flex`, justifyContent: `center` }}
          >
            <img
              className="img-fluid"
              alt="welcome-img"
              src={WelcomeImg}
              width="500px"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OTP;
