import React, { useState, createContext } from "react";

export const Context = createContext();

export const ContextProvider = ({ children }) => {
  const [language, setLanguage] = useState("");
  const [mobile, setMobile] = useState();

  return (
    <Context.Provider
      value={{
        language,
        setLanguage,
        mobile,
        setMobile,
      }}
    >
      {children}
    </Context.Provider>
  );
};
