import React, { useCallback, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import blogHeroImg from "../images/hero-images/blog-img.png";
import PrimaryHero from "../components/PrimaryHero";
import Border from "../components/Border";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";
import axios from "axios";
import apis from "../config/apis";
import headers from "../config/header";
import Moment from 'react-moment';
import 'moment-timezone';

const initial = {
  Title: "",
  Summary: "",
};

export default function BlogPage() {
  const id = useParams();
  const [data, setData] = useState(initial);

  const fatchLaws = useCallback(() => {
    const body = {
      _id: id.id,
    };
    axios
      .post(apis + "admin/findUpdateCornerById", body, headers)
      .then((res) => {
        setData(res?.data?.data);
        console.log(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    fatchLaws();
  }, [fatchLaws]);

  return (
    <>
      <Navbar />

      <PrimaryHero title="Update corner" paragraph="Lorem Ipsum is simply dummy text of the printing industry." heroImg={blogHeroImg} />

      <Border />

      <section>
        <div className="container">
          <div className="row my-3">
            <div className="col-12">
              <h3 className="mb-3">{data.Title}</h3>
            </div>
            <div className="col-12 writer_auther">
              <div className="writer">{data.autherName}</div>
              <p><Moment fromNow ago>{data.updatedAt}</Moment> ago</p>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 col-12">
              <img className="img-fluid mb-3 blog_img" alt="blog-img" src={data.imageOrVideo} />
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12 col-12 ">
              <p>{data.Summary}</p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
