import React from "react";
import { NavLink } from "react-router-dom";

const ActBtn = ({ actBtn, doSomethingAfterClick, id }) => {

  return (
    <div className="acts-btn-group mt-4 acts-btn-wrapper">
      <NavLink
        activeClassName="acts-active"
        className="acts-btn"
        to={`/acts/${id.id}`}
        exact
      >
        Sections
      </NavLink>
      <NavLink
        activeClassName="acts-active"
        className="acts-btn"
        to={`/acts-chapters/${id.id}`}
        exact
      >
        Chapter
      </NavLink>
      {/* <NavLink
        activeClassName="acts-active"
        className="acts-btn"
        to={`/judgements/${id.id}`}
        exact
      >
        Judgements
      </NavLink>
      <NavLink
        activeClassName="acts-active"
        className="acts-btn"
        to={`/rules/${id.id}`}
        exact
      >
        Rules
      </NavLink> */}
      <NavLink
        activeClassName="acts-active"
        className="acts-btn"
        to={`/acts-details/${id.id}`}
        exact
      >
        Details
      </NavLink>
    </div>
  );
};

export default ActBtn;
