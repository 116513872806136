import React, { useCallback, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import blogHeroImg from "../images/hero-images/blog-img.png";
// import blogImg from "../images/blog-1.png";
import PrimaryHero from "../components/PrimaryHero";
import Border from "../components/Border";
import BlogItem from "../components/BlogItem";
import { Link } from "react-router-dom";
// import { useHistory } from "react-router";
import axios from "axios";
import apis from "../config/apis";
import headers from "../config/header";

export default function Blogs() {
  // const history = useHistory();
  const [data, setData] = useState([]);
  const [IsBlog, setIsBlog] = useState(true)
  const [IsNews, setIsNews] = useState(false)
  const [IsAnnouncement, setIsAnnouncement] = useState(false)
  const [IsCase_Study, setIsCase_Study] = useState(false)


  const fatchBlogs = useCallback(() => {
    axios
      .get(apis + "admin/getUpdateCorners", headers)
      .then((res) => {
        setData(res?.data?.data);
        console.log(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onBlog = (e) => {
    if (e.target.checked) {
      setIsBlog(true)
      // setIsNews(false)
      // setIsAnnouncement(false)
      // setIsCase_Study(false)
    } else {
      setIsBlog(false)
    }
  }

  const onNews = (e) => {
    if (e.target.checked) {
      setIsNews(true)
      // setIsBlog(false)
      // setIsAnnouncement(false)
      // setIsCase_Study(false)
    } else {
      setIsNews(false)
    }
  }

  const onAnnouncement = (e) => {
    if (e.target.checked) {
      setIsAnnouncement(true)
      // setIsBlog(false)
      // setIsNews(false)
      // setIsCase_Study(false)
    } else {
      setIsAnnouncement(false)
    }
  }

  const onCase_Study = (e) => {
    if (e.target.checked) {
      setIsCase_Study(true)
      // setIsBlog(false)
      // setIsNews(false)
      // setIsAnnouncement(false)
    } else {
      setIsCase_Study(false)
    }
  }

  useEffect(() => {
    fatchBlogs();
  }, [fatchBlogs]);
  return (
    <>
      <Navbar />

      <PrimaryHero title="Update Corner" paragraph="Lorem Ipsum is simply dummy text of the printing industry." heroImg={blogHeroImg} />

      <Border />

      <section>
        <div className="container my-5">
          <div className="update_corner_filter">
            <label class="update_control" for="blog">
              <input type="checkbox" name="blog" id="blog" onChange={(e) => onBlog(e)} checked={IsBlog} />
              <span class="control__content">
                Blogs
              </span>
            </label>

            <label class="update_control" for="news">
              <input type="checkbox" name="news" id="news" onChange={(e) => onNews(e)} />
              <span class="control__content">
                News
              </span>
            </label>

            <label class="update_control" for="annou">
              <input type="checkbox" name="annou" id="annou" onChange={(e) => onAnnouncement(e)} />
              <span class="control__content">
                Announcement
              </span>
            </label>

            <label class="update_control" for="case">
              <input type="checkbox" name="case" id="case" onChange={(e) => onCase_Study(e)} />
              <span class="control__content">
                Case-Stydy
              </span>
            </label>
          </div>
          {IsBlog &&
            <>
              {data.length < 1
                ? "No Data found :("
                : data.filter((active) => active.IsBlog)
                  .map((blogs, index) => {
                    return (
                      <Link
                        to={`/blog/${blogs._id}`}
                        style={{
                          textDecoration: `none`,
                          color: `#000000`,
                          cursor: `pointer`,
                        }}
                        key={index}
                      >
                        <div className="row my-3">
                          <BlogItem
                            id={blogs._id}
                            blogImg={blogs.imageOrVideo}
                            blogTitle={blogs.Title}
                            blogSummary={blogs.Summary}
                          />
                        </div>
                      </Link>
                    );
                  })
              }
            </>
          }
          {IsNews &&
            <>
              {data.length < 1
                ? "No Data found :("
                : data.filter((active) => active.IsNews)
                  .map((blogs, index) => {
                    return (
                      <Link
                        to={`/blog/${blogs._id}`}
                        style={{
                          textDecoration: `none`,
                          color: `#000000`,
                          cursor: `pointer`,
                        }}
                        key={index}
                      >
                        <div className="row my-3">
                          <BlogItem
                            id={blogs._id}
                            blogImg={blogs.imageOrVideo}
                            blogTitle={blogs.Title}
                            blogSummary={blogs.Summary}
                          />
                        </div>
                      </Link>
                    );
                  })
              }
            </>
          }
          {IsAnnouncement &&
            <>
              {data.length < 1
                ? "No Data found :("
                : data.filter((active) => active.IsAnnouncement)
                  .map((blogs, index) => {
                    return (
                      <Link
                        to={`/blog/${blogs._id}`}
                        style={{
                          textDecoration: `none`,
                          color: `#000000`,
                          cursor: `pointer`,
                        }}
                        key={index}
                      >
                        <div className="row my-3">
                          <BlogItem
                            id={blogs._id}
                            blogImg={blogs.imageOrVideo}
                            blogTitle={blogs.Title}
                            blogSummary={blogs.Summary}
                          />
                        </div>
                      </Link>
                    );
                  })
              }
            </>
          }
          {IsCase_Study &&
            <>
              {data.length < 1
                ? "No Data found :("
                : data.filter((active) => active.IsCase_Study)
                  .map((blogs, index) => {
                    return (
                      <Link
                        to={`/blog/${blogs._id}`}
                        style={{
                          textDecoration: `none`,
                          color: `#000000`,
                          cursor: `pointer`,
                        }}
                        key={index}
                      >
                        <div className="row my-3">
                          <BlogItem
                            id={blogs._id}
                            blogImg={blogs.imageOrVideo}
                            blogTitle={blogs.Title}
                            blogSummary={blogs.Summary}
                          />
                        </div>
                      </Link>
                    );
                  })
              }
            </>
          }
        </div>
      </section>

      <Footer />
    </>
  );
}
