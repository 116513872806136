import React, { useContext, useState } from "react";
import WelcomeLogo from "../images/welcome-logo.png";
import WelcomeImg from "../images/welcome-bg.png";
import WelcomeBackground from "../images/welcome-background.png";
import { useHistory } from "react-router-dom";
import { Context } from "../data/context";
import apis from "../config/apis";
import axios from "axios";
import headers from "../config/header";

const initial = {
  phone: "",
  name: "",
  email: "",
  state: "",
  city: "",
  role: "",
  language: "",
  firebaseToken: "",
};

const CompleteProfile = () => {
  const history = useHistory();
  const [form, setForm] = useState(initial);
  const { language, mobile } = useContext(Context);

  const onSignUp = () => {
    const body = {
      phone: mobile,
      name: form.name,
      email: form.email,
      state: form.state,
      city: form.city,
      role: form.role,
      language: language,
      firebaseToken: "",
    };
    axios
      .post(apis + "user/completeProfile", body, headers)
      .then((res) => {
        if (res.data.success) {
          localStorage.setItem("k2Laws", JSON.stringify(res.data.data));
          history.push("/");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <section>
      <div
        className="container welcome-wrapper bg-blue"
        style={{
          backgroundImage: `url(${WelcomeBackground})`,
          backgroundSize: `cover`,
          height: `auto`,
          backgroundRepeat: `no-repeat`,
          borderRadius: `20px`,
        }}
      >
        <div className="row welcome-items">
          <div className="col-lg-4">
            <img
              className="img-fluid"
              alt="welcome-logo"
              src={WelcomeLogo}
              width="150px"
            />
            <h3 className="text-white">Sign Up</h3>
            <p className="text-white" style={{ fontSize: `14px` }}>
              Sign Up by entering your credentials below
            </p>
            <hr style={{ background: `#fff` }} />
            <p className="text-white">Enter your mobile number</p>
            <form>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control welcome-form"
                  id="formGroupExampleInput"
                  placeholder="Enter your name"
                  value={form.name}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      name: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control welcome-form"
                  id="formGroupExampleInput"
                  placeholder="Enter your email"
                  value={form.email}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      email: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <select
                  className="form-control welcome-form"
                  id="exampleFormControlSelect1"
                  value={form.state}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      state: e.target.value,
                    });
                  }}
                >
                  <option selected>Select your state</option>
                  <option value="Surat">Surat</option>
                  <option value="Surat">Surat</option>
                  <option value="Surat">Surat</option>
                </select>
              </div>
              <div className="form-group">
                <select
                  className="form-control welcome-form"
                  id="exampleFormControlSelect1"
                  value={form.role}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      role: e.target.value,
                    });
                  }}
                >
                  <option selected>Select your role</option>
                  <option value="Advocate">Advocate</option>
                  <option value="Police">Police</option>
                  <option value="CA">CA</option>
                </select>
              </div>
              <button
                type="button"
                className="welcome-btn"
                onClick={() => onSignUp()}
              >
                Next
              </button>
            </form>
          </div>
          <div
            className="col-lg-8"
            style={{ display: `flex`, justifyContent: `center` }}
          >
            <img
              className="img-fluid"
              alt="welcome-img"
              src={WelcomeImg}
              width="500px"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompleteProfile;
