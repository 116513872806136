import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import heroJudgements from "../images/hero-images/judgements.png";
import Border from "../components/Border";
import BigCard from "../components/BigCard";
import Pagination from "@material-ui/lab/Pagination";
import { Link } from "react-router-dom";
import axios from "axios";
import apis from "../config/apis";

const Judgement = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  const handleChange = (event, value) => {
    setPage(value);
    judgementData(value);
  };

  const judgementData = (pageNum) => {
    const config = {
      params: {
        page: pageNum,
        size: 5,
        // pageNumber: currentPage,
      },
    };
    axios
      .get(apis + "admin/getJudgementsByPage", config)
      .then((res) => {
        setData(res?.data?.data?.laws);
        console.log(res?.data?.data?.laws);
        setCount(res?.data?.data?.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    judgementData(page);
  }, [page]);

  return (
    <>
      <Navbar />

      <Hero value="Judgements" img={heroJudgements} />

      <Border />

      <section>
        <div className="container">{/* <ActBtn id={id} /> */}</div>
      </section>

      <section>
        <div className="container my-3">
          <div className="row">
            {data.map((judgements) => {
              return (
                <div
                  className="col-lg-12 col-md-12 col-sm-12 col-12 my-3"
                  key={judgements._id}
                >
                  <Link
                    to={`/judgements-details/${judgements._id}`}
                    style={{
                      textDecoration: `none`,
                      color: `#000000`,
                      cursor: `pointer`,
                    }}
                  >
                    <BigCard
                      id={judgements._id}
                      heading={judgements.en.judgementYear}
                      title={judgements.en.judgementTitle}
                    />
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section>
        <div className="container d-flex justify-content-center mb-3">
          <Pagination count={count} page={page} onChange={handleChange} />
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Judgement;
