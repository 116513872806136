import React, { useCallback, useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Border from "../components/Border";
import LawDetailsCard from "../components/LawDetailsCard";
import CardInShort from "../components/CardInShort";
import heroLawsDetails from "../images/hero-images/laws-details.png";
import { Markup } from "interweave";
import { useParams } from "react-router-dom";
import axios from "axios";
import apis from "../config/apis";
import headers from "../config/header";

const initial = {
  en: { lawsTitle: "", lawsNumber: "" },
};

const LawsDetails = () => {
  const id = useParams();
  const [data, setData] = useState({});
  const [dataHindi, setDataHindi] = useState({});
  const [dataGujarati, setDataGujarati] = useState({});
  const [dataMarathi, setDataMarathi] = useState({});
  const [lawsId, setLawsId] = useState("");
  const [lawsData, setLawsData] = useState(initial);
  const [IsEnglish, setIsEnglish] = useState(true)
  const [IsHindi, setIsHindi] = useState(false)
  const [IsGujarati, setIsGujarati] = useState(false)
  const [IsMarathi, setMarathi] = useState(false)

  const [IsHindiAva, setHindiAva] = useState(false)
  const [IsGujaratiAva, setGujaratiAva] = useState(false)
  const [IsMarathiAva, setMarathiAva] = useState(false)


  const fatchLaws = useCallback(() => {
    const body = {
      _id: id.id,
    };
    axios
      .post(apis + "admin/findSectionById", body, headers)
      .then((res) => {
        setData(res?.data?.data?.en);
        setDataHindi(res?.data?.data?.hi)
        setDataGujarati(res?.data?.data?.gj)
        setDataMarathi(res?.data?.data?.mh)
        setLawsData(res?.data?.data?.laws);
        console.log(res?.data?.data, '88888888888888888888888888888');
        setLawsId(res?.data?.data?.laws?._id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);


  // if (id.id === null) {

  // } else {
  //   if (dataHindi === null) {
  //     setHindiAva(true)
  //     console.log('_________');
  //   }
  //   if (dataGujarati === null) {
  //     setGujaratiAva(true)
  //   }
  //   if (dataMarathi === null) {
  //     setMarathiAva(true)
  //   }
  // }
  const onEnglish = (e) => {
    if (e.target.checked) {
      setIsEnglish(true)
      setIsHindi(false)
      setIsGujarati(false)
      setMarathi(false)
    } else {
      setIsEnglish(false)
    }
  }

  const onHindi = (e) => {
    if (e.target.checked) {
      setIsHindi(true)
      setIsEnglish(false)
      setIsGujarati(false)
      setMarathi(false)
    } else {
      setIsHindi(false)
    }
  }

  const onGujarati = (e) => {
    if (e.target.checked) {
      setIsGujarati(true)
      setIsEnglish(false)
      setIsHindi(false)
      setMarathi(false)
    } else {
      setIsGujarati(false)
    }
  }

  const onMarathi = (e) => {
    if (e.target.checked) {
      setMarathi(true)
      setIsEnglish(false)
      setIsHindi(false)
      setIsGujarati(false)
    } else {
      setMarathi(false)
    }
  }

  // settimeout for lang
  setTimeout(() => {
    if (dataHindi === null) {
      setHindiAva(true)
    }
    if (dataGujarati === null) {
      setGujaratiAva(true)
    }
    if (dataMarathi === null) {
      setMarathiAva(true)
    }
  }, 500);


  useEffect(() => {
    fatchLaws();
  }, [fatchLaws]);

  return (
    <>
      <Navbar />

      <Hero value="Laws Details" img={heroLawsDetails} />

      <Border />

      <section>
        <div className="container">
          <div className="form_filters_lang">
            <label class="update_control" for="English">
              <input type="checkbox" name="English" id="English" onChange={(e) => onEnglish(e)} checked={IsEnglish} />
              <span class="control__content">
                English
              </span>
            </label>

            <label class="update_control" for="Hindi">
              <input type="checkbox" name="Hindi" id="Hindi" onChange={(e) => onHindi(e)} checked={IsHindi} disabled={IsHindiAva} />
              <span class="control__content">
                Hindi
              </span>
            </label>

            <label class="update_control" for="Gujarati">
              <input type="checkbox" name="Gujarati" id="Gujarati" onChange={(e) => onGujarati(e)} checked={IsGujarati} disabled={IsGujaratiAva} />
              <span class="control__content">
                Gujarati
              </span>
            </label>

            <label class="update_control" for="Marathi">
              <input type="checkbox" name="Marathi" id="Marathi" onChange={(e) => onMarathi(e)} checked={IsMarathi} disabled={IsMarathiAva} />
              <span class="control__content">
                Marathi
              </span>
            </label>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row my-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 my-2">
              {IsEnglish &&
                <LawDetailsCard
                  lawNumber={lawsData?.en?.lawNumber}
                  title={lawsData?.en?.lawTitle}
                  id={id?._id}
                />
              }
              {IsHindi &&
                <LawDetailsCard
                  lawNumber={lawsData?.hi?.lawNumber}
                  title={lawsData?.hi?.lawTitle}
                  id={id?._id}
                />
              }
              {IsGujarati &&
                <LawDetailsCard
                  lawNumber={lawsData?.gj?.lawNumber}
                  title={lawsData?.gj?.lawTitle}
                  id={id?._id}
                />
              }
              {IsMarathi &&
                <LawDetailsCard
                  lawNumber={lawsData?.mh?.lawNumber}
                  title={lawsData?.mh?.lawTitle}
                  id={id?._id}
                />
              }
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <header>
            <h4 className="font-weight-700">Section</h4>
          </header>
          {IsEnglish &&
            <p className="font-weight-400">{data.sectionTitle}</p>
          }
          {IsHindi &&
            <p className="font-weight-400">{dataHindi.sectionTitle}</p>
          }
          {IsGujarati &&
            <p className="font-weight-400">{dataGujarati.sectionTitle}</p>
          }
          {IsMarathi &&
            <p className="font-weight-400">{dataMarathi.sectionTitle}</p>
          }
        </div>
      </section>

      <section>
        <div className="container">
          <header>
            <h4 className="font-weight-700">Summary</h4>
          </header>

          <div className="font-weight-400">
            {IsEnglish &&
              <Markup content={data.sectionSummary || 'null'} />
            }
            {IsHindi &&
              <Markup content={dataHindi.sectionSummary || 'null'} />
            }
            {IsGujarati &&
              <Markup content={dataGujarati.sectionSummary} />
            }
            {IsMarathi &&
              <Markup content={dataMarathi.sectionSummary} />
            }
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-4 mb-5">
              {IsEnglish &&
                <CardInShort
                  actNumber="In Short"
                  actInShortDescription={data.sectionInShort}
                  id={lawsId}
                />
              }
              {IsHindi &&
                <CardInShort
                  actNumber="In Short"
                  actInShortDescription={dataHindi.sectionInShort}
                  id={lawsId}
                />
              }
              {IsGujarati &&
                <CardInShort
                  actNumber="In Short"
                  actInShortDescription={dataGujarati.sectionInShort}
                  id={lawsId}
                />
              }
              {IsMarathi &&
                <CardInShort
                  actNumber="In Short"
                  actInShortDescription={dataMarathi.sectionInShort}
                  id={lawsId}
                />
              }
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default LawsDetails;
