import React from "react";
import heroBackground from "../images/hero-bg.png";

const PrimaryHero = ({ title, paragraph, heroImg }) => (
  <section
    style={{
      backgroundImage: `url(${heroBackground})`,
      backgroundSize: `cover`,
      height: `auto`,
      backgroundRepeat: `no-repeat`,
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 hero-home">
          <div>
            <h1 className="text-uppercase lg-text-3 font-weight-700">
              {title}
            </h1>
            <p>{paragraph}</p>
          </div>
          <div>
            <img
              className="img-fluid"
              alt="hero-img"
              src={heroImg}
              width="600px"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default PrimaryHero;
