import React from "react";
import cardBackground from "../images/laws-details-background-1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";

const BigCard = ({ heading, title, id }) => {
  return (
    <div
      className="card border-0 card-shadow border-20"
      style={{
        height: `auto`,
        backgroundImage: `url(${cardBackground})`,
        backgroundSize: `cover`,
        cursor: `pointer`,
      }}
    >
      <div className="judgements-border-1"></div>
      <div className="card-body padding-2">
        <div className="judgements-card">
          <h4 className="card-title font-weight-600 mb-0 ml-3 text-center">
            {heading}
          </h4>
          <div className="mx-4">
            <div className="vertical-line-1"></div>
            <div className="vertical-line-2"></div>
          </div>
          <h5 className="mb-0">{title}</h5>
        </div>
        <div className="bookmark-sm-icon">
          <FontAwesomeIcon icon={faBookmark} />
        </div>
      </div>
    </div>
  );
};

export default BigCard;
