import React from "react";
import heroBackground from "../images/hero-bg.png";

const Hero = ({ value, img }) => (
  <section
    style={{
      backgroundImage: `url(${heroBackground})`,
      backgroundSize: `cover`,
      height: `auto`,
      backgroundRepeat: `no-repeat`,
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 hero">
          <div>
            <h1 className="text-uppercase lg-text-2 font-weight-700">
              {value}
            </h1>
          </div>
          <div>
            <img className="img-fluid" alt="hero-img" src={img} width="400px" />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Hero;
