import React, { useCallback, useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Border from "../components/Border";
import ActBtn from "../components/ActBtn";
import CardInShort from "../components/CardInShort";
import ActsChapterSelect from "../components/ActsChapterSelect";
import heroTaxActChapter from "../images/hero-images/tax-act-chapter.png";
import axios from "axios";
import apis from "../config/apis";
import { useParams } from "react-router-dom";

const initial = [
  {
    laws: {
      en: {
        lawNumber: "",
        lawTitle: "",
      },
    },
  },
];

export default function ActsChapters() {
  const [data, setData] = useState([]);
  const [laws, setLaws] = useState(initial);
  const id = useParams();
  console.log(laws, "laws");

  const fatchLaws = useCallback(() => {
    const body = {
      _id: id.id,
    };
    axios
      .post(apis + "admin/findChapterByLawId", body)
      .then((res) => {
        setData(res?.data?.data);
        console.log(res?.data?.data, 'chapter');
        if (res?.data?.data[0].laws === []) {
          setLaws(initial);
        } else {
          setLaws(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    fatchLaws();
  }, [fatchLaws]);

  return (
    <>
      <Navbar />

      <Hero value="Tax Act" img={heroTaxActChapter} />

      <Border />

      <section>
        <div className="container">
          <ActBtn actBtn="Details" id={id} />
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-4 mb-2">
              <CardInShort
                actNumber={laws[0].laws.en.lawNumber}
                actInShortDescription={laws[0].laws.en.lawTitle}
                id={laws[0].laws._id}
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row my-4">
            {data.map((actsChapters, index) => {
              return (
                <div
                  className="col-lg-4 col-md-6 col-sm-12 col-12 my-2"
                  key={index}
                >
                  <ActsChapterSelect data={actsChapters} />
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
