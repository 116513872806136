import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Index from "./pages/Index";
import Judgements from "./pages/Judgements";
import JudgementsDetails from "./pages/JudgementsDetails";
import Laws from "./pages/Laws";
import LawsDetails from "./pages/LawsDetails";
import Acts from "./pages/Acts";
import ActsChapters from "./pages/ActsChapters";
import ActsDetails from "./pages/ActsDetails";
import YourBookmark from "./pages/YourBookmark";
import WelcomePage from "./pages/WelcomePage";
import SignUp from "./pages/SignUp";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PageNotFound from "./pages/PageNotFound";
import { ContextProvider } from "./data/context";
import OTP from "./pages/OTP";
import CompleteProfile from "./pages/CompleteProfile";
import Blogs from "./pages/Blogs";
import BlogPage from "./pages/BlogPage";
import Judgement from "./pages/Judgement";
import About from "./pages/About";
import Forms from "./pages/Forms";
import FormDetails from "./pages/Formpage";
import Emergency from "./pages/Emergency";
// toast
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Scrolltotop from "./config/Scrolltotop";

function App() {
  return (
    <ContextProvider>
      <Router>
        <Scrolltotop />
        <Switch>
          <Route path="/" exact component={Index} />
          <Route path="/judgement" component={Judgement} />
          <Route path="/judgements/:id" component={Judgements} />
          <Route path="/judgements-details/:id" component={JudgementsDetails} />
          <Route path="/laws" component={Laws} />
          <Route path="/laws-details/:id" component={LawsDetails} />
          <Route path="/acts/:id" component={Acts} />
          <Route path="/acts-chapters/:id" component={ActsChapters} />
          <Route path="/acts-details/:id" component={ActsDetails} />
          <Route path="/your-bookmark" component={YourBookmark} />
          <Route path="/blogs" component={Blogs} />
          <Route path="/blog/:id" component={BlogPage} />
          <Route path="/about" component={About} />
          <Route path="/welcome" component={WelcomePage} />
          <Route path="/register" component={SignUp} />
          <Route path="/otp" component={OTP} />
          <Route path="/complete-profile" component={CompleteProfile} />
          <Route path="/forms" component={Forms} />
          <Route path="/form-details/:id" component={FormDetails} />
          <Route path="/emergency" component={Emergency} />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
      <ToastContainer />
    </ContextProvider>
  );
}

export default App;
