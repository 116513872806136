import React from "react";

const BlogItem = ({ blogImg, blogTitle, blogSummary }) => {
  return (
    <>
      <div className="col-lg-5 col-md-6 col-sm-12 col-12">
        <img className="img-fluid blog_img" alt="blog-img" src={blogImg} />
      </div>
      <div className="col-lg-7 col-md-6 col-sm-12 col-12 d-flex justify-content-center flex-column">
        <h3
          className="mt-2"
          style={{
            display: `-webkit-box`,
            WebkitLineClamp: `2`,
            WebkitBoxOrient: `vertical`,
            overflow: `hidden`,
          }}
        >
          {blogTitle}
        </h3>
        <p
          style={{
            display: `-webkit-box`,
            WebkitLineClamp: `4`,
            WebkitBoxOrient: `vertical`,
            overflow: `hidden`,
          }}
        >
          {blogSummary}
        </p>
      </div>
    </>
  );
};

export default BlogItem;
