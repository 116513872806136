import React, { useCallback, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import EnglishHeroImg from "../images/hero-images/formpage.png";
import PrimaryHero from "../components/PrimaryHero";
import Border from "../components/Border";
import axios from "axios";
import apis from "../config/apis";
import headers from "../config/header";
import { useParams } from "react-router-dom";
import cardBackground from "../images/laws-details-background-1.png";
import FormDownloadCard from "../components/FormDownloadCard";



export default function FormDetails() {
    // const history = useHistory();
    const { id } = useParams();
    const [formData, setFormData] = useState([]);
    const [formList, setFormList] = useState([]);
    const [IsEnglish, setIsEnglish] = useState(true)
    const [IsHindi, setIsHindi] = useState(false)
    const [IsGujarati, setIsGujarati] = useState(false)
    const [IsMarathi, setMarathi] = useState(false)



    const fatchFormData = useCallback(() => {
        const body = {
            "_id": id
        }
        axios
            .post(apis + "admin/findTypesOfFormById", body, headers)
            .then((res) => {
                setFormData(res?.data?.data);
                console.log(res?.data?.data, 'form data');
            })
            .catch((err) => {
                console.log(err);
            });

        // search list of form
        const body2 = {
            "typeOfForm": id
        }
        axios
            .post(apis + "admin/searchFormList", body2, headers)
            .then((res) => {
                setFormList(res?.data?.data);
                console.log(res?.data?.data, 'form list');
            })
            .catch((err) => {
                console.log(err);
            });
    }, [id]);


    const onEnglish = (e) => {
        if (e.target.checked) {
            setIsEnglish(true)
            setIsHindi(false)
            setIsGujarati(false)
            setMarathi(false)
        } else {
            setIsEnglish(false)
        }
    }

    const onHindi = (e) => {
        if (e.target.checked) {
            setIsHindi(true)
            setIsEnglish(false)
            setIsGujarati(false)
            setMarathi(false)
        } else {
            setIsHindi(false)
        }
    }

    const onGujarati = (e) => {
        if (e.target.checked) {
            setIsGujarati(true)
            setIsEnglish(false)
            setIsHindi(false)
            setMarathi(false)
        } else {
            setIsGujarati(false)
        }
    }

    const onMarathi = (e) => {
        if (e.target.checked) {
            setMarathi(true)
            setIsEnglish(false)
            setIsHindi(false)
            setIsGujarati(false)
        } else {
            setMarathi(false)
        }
    }


    useEffect(() => {
        fatchFormData();
    }, [fatchFormData]);
    return (
        <>
            <Navbar />
            <PrimaryHero title="Forms" paragraph="Lorem Ipsum is simply dummy text of the printing industry." heroImg={EnglishHeroImg} />
            <Border />
            <section className="forms">
                <div className="container">
                    <div className="form_filters_lang">
                        <label class="update_control" for="English">
                            <input type="checkbox" name="English" id="English" onChange={(e) => onEnglish(e)} checked={IsEnglish} />
                            <span class="control__content">
                                English
                            </span>
                        </label>

                        <label class="update_control" for="Hindi">
                            <input type="checkbox" name="Hindi" id="Hindi" onChange={(e) => onHindi(e)} checked={IsHindi} />
                            <span class="control__content">
                                Hindi
                            </span>
                        </label>

                        <label class="update_control" for="Gujarati">
                            <input type="checkbox" name="Gujarati" id="Gujarati" onChange={(e) => onGujarati(e)} checked={IsGujarati} />
                            <span class="control__content">
                                Gujarati
                            </span>
                        </label>

                        <label class="update_control" for="Marathi">
                            <input type="checkbox" name="Marathi" id="Marathi" onChange={(e) => onMarathi(e)} checked={IsMarathi} />
                            <span class="control__content">
                                Marathi
                            </span>
                        </label>
                    </div>

                    <div
                        className="card border-0 card-shadow border-20 form_d"
                        style={{
                            height: `auto`,
                            backgroundImage: `url(${cardBackground})`,
                            backgroundSize: `cover`,
                            cursor: `pointer`,
                        }}
                    >
                        {IsEnglish &&
                            <>
                                <h2>{formData.FormName}</h2>
                                <p>{formData.FormDescription}</p>
                            </>
                        }
                        {IsHindi &&
                            <>
                                <h2>{formData.hi.FormName}</h2>
                                <p>{formData.hi.FormDescription}</p>
                            </>
                        }
                        {IsGujarati &&
                            <>
                                <h2>{formData.gj.FormName}</h2>
                                <p>{formData.gj.FormDescription}</p>
                            </>
                        }
                        {IsMarathi &&
                            <>
                                <h2>{formData.mh.FormName}</h2>
                                <p>{formData.mh.FormDescription}</p>
                            </>
                        }
                    </div>

                    <div className="row my-4">
                        {IsEnglish &&
                            <>
                                {formList.length < 1
                                    ? "No Data found :("
                                    : formList.map((list, index) => {
                                        return (
                                            <div
                                                className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 my-2"
                                                key={index}
                                            >
                                                <FormDownloadCard
                                                    heading={index + 1}
                                                    title={list.formName}
                                                    id={`/form-details/${list._id}`}
                                                />
                                            </div>
                                        );
                                    })
                                }
                            </>
                        }
                        {IsHindi &&
                            <>
                                {formList.length < 1
                                    ? "No Data found :("
                                    : formList.map((list, index) => {
                                        return (
                                            <div
                                                className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 my-2"
                                                key={index}
                                            >
                                                <FormDownloadCard
                                                    heading={index + 1}
                                                    title={list.hi.FormName}
                                                    id={`/form-details/${list._id}`}
                                                />
                                            </div>
                                        );
                                    })
                                }
                            </>
                        }
                        {IsGujarati &&
                            <>
                                {formList.length < 1
                                    ? "No Data found :("
                                    : formList.map((list, index) => {
                                        return (
                                            <div
                                                className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 my-2"
                                                key={index}
                                            >
                                                <FormDownloadCard
                                                    heading={index + 1}
                                                    title={list.gj.FormName}
                                                    id={`/form-details/${list._id}`}
                                                />
                                            </div>
                                        );
                                    })
                                }
                            </>
                        }
                        {IsMarathi &&
                            <>
                                {formList.length < 1
                                    ? "No Data found :("
                                    : formList.map((list, index) => {
                                        return (
                                            <div
                                                className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 my-2"
                                                key={index}
                                            >
                                                <FormDownloadCard
                                                    heading={index + 1}
                                                    title={list.mh.FormName}
                                                    id={`/form-details/${list._id}`}
                                                />
                                            </div>
                                        );
                                    })
                                }
                            </>
                        }
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}
