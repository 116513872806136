import React from "react";
import { Markup } from "interweave";
import BookmarkFilled from "../images/bookmark-filled.png";
import BookmarkUnfilled from "../images/bookmark-unfilled.png";
import { useHistory } from "react-router";
import cardBackground from "../images/bg-1.png";
import axios from "axios";
import apis from "../config/apis";
import headers from "../config/header";
import ShowMoreText from "react-show-more-text";

const PrimaryCard = ({ title, description, role, id, isBookMark }) => {
  const history = useHistory();

  const onBookmarkRemove = () => { };

  const onBookmark = (id) => {
    const data = JSON.parse(localStorage.getItem("k2Laws"));
    if (data) {
      const body = {
        content: "",
        contentId: id,
        user: data._id,
        bookmark_type: "Law",
      };
      axios
        .post(apis + `user/addBookmark`, body, headers)
        .then((res) => {
          console.log(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
      //Open editor and and call bookmark api
    } else {
      //open popup and it should have to login message
    }
  };

  return (
    <div
      className="card border-0 card-shadow border-20"
      style={{
        height: `auto`,
        backgroundImage: `url(${cardBackground})`,
        backgroundSize: `cover`,
        cursor: `pointer`,
      }}
    >
      <div className="card-body p-75" style={{ height: "300px" }}>
        <h5
          className="card-title font-weight-600"
          onClick={() => {
            history.push(`/acts/${id}`);
          }}
        >
          {title}
        </h5>

        <div
          className="card-text font-weight-400"
          style={{
            display: `-webkit-box`,
            WebkitLineClamp: `6`,
            WebkitBoxOrient: `vertical`,
            overflow: `hidden`,
          }}
        >
          <ShowMoreText
            lines={1}
            more="Show more"
            less="Show less"
            expanded={false}
            width={400}
            anchorClass="more-anchor"
          >
            <Markup content={description} />
          </ShowMoreText>
          {/* <Markup content={description} /> */}
        </div>

        <div className="color-group mt-4 mb-3">
          <div className="color-item-1"></div>
          <div className="color-item-2"></div>
        </div>
        <div className="card-flex mb-0">
          {/* {role} */}
          {/* <span className="bookmark-far-icon ml-auto">
            <FontAwesomeIcon icon={faSave} />
          </span> */}
          {isBookMark && (
            <span
              className="bookmark-icon ml-auto"
              onClick={() => onBookmarkRemove(id)}
            >
              <img
                className="img-fluid"
                alt="bookmark-filled"
                src={BookmarkFilled}
              />
            </span>
          )}
          {!isBookMark && (
            <span
              className="bookmark-icon ml-auto"
              onClick={() => onBookmark(id)}
            >
              <img
                className="img-fluid"
                alt="bookmark-unfilled"
                src={BookmarkUnfilled}
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrimaryCard;
