import React, { useCallback, useEffect, useState } from "react";
import Footer from "../components/Footer";
import AboutUs from "../components/AboutUs";
import Navbar from "../components/Navbar";
import PrimaryHero from "../components/PrimaryHero";
import Label from "../components/Label";
import homeImg from "../images/hero-images/home.png";
import Blogs from "../components/Blogs";
import PrimaryTitle from "../components/PrimaryTitle";
import ActsSlider from "../components/ActsSlider";
import JudgementsSlider from "../components/JudgementsSlider";
import Bookmark from "../components/Bookmark";
import bData from "../json/bookmarkData.json";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import axios from "axios";
import apis from "../config/apis";
import headers from "../config/header";
import formImage from "../images/hero-images/forms.png";
import FormSlider from "../components/FormSilder";
import commingSoon from '../images/comming_soon.png'

export default function Index() {
  const history = useHistory();
  const [blogs, setBlogs] = useState([]);

  const fatchBlogs = useCallback(() => {
    axios
      .get(apis + "admin/getUpdateCorners", headers)
      .then((res) => {
        setBlogs(res?.data?.data);
        console.log(res?.data?.data, 'blog');
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    fatchBlogs();
  }, [fatchBlogs]);

  return (
    <>
      <Navbar />

      <PrimaryHero title="Welcome to K2 helplaw" heroImg={homeImg} />

      <AboutUs />
      {/* LAWS  */}
      <section>
        <div className="container mt-5 mb-4">
          <PrimaryTitle primaryTitle="Acts" />
        </div>
      </section>
      <section>
        <div className="container-xl my-4">
          <div
            className="font-weight-500 d-flex justify-content-end"
            onClick={() => history.push(`/laws`)}
            style={{ cursor: `pointer` }}
          >
            View all
          </div>
          <ActsSlider />
        </div>
      </section>
      {/* LAWS END */}

      {/* JUDGEMENTS */}
      {/* <Label />
      <section>
        <div className="container mt-5 mb-4">
          <PrimaryTitle primaryTitle="Judgements" />
        </div>
      </section>
      <section>
        <div className="container-xl my-4">
          <div
            className="font-weight-500 d-flex justify-content-end"
            onClick={() => history.push(`/judgement`)}
            style={{ cursor: `pointer` }}
          >
            View all
          </div>
          <JudgementsSlider />
        </div>
      </section> */}
      {/* JUDGEMENTS END */}

      {/* FORMS */}
      <Label Title="Forms" Image={formImage} />
      <section>
        <div className="container mt-5 mb-4">
          <PrimaryTitle primaryTitle="FORMS" />
        </div>
      </section>
      <section>
        <div className="container-xl my-4">
          <div
            className="font-weight-500 d-flex justify-content-end"
            onClick={() => history.push(`/forms`)}
            style={{ cursor: `pointer` }}
          >
            View all
          </div>
          <FormSlider />
        </div>
      </section>
      {/* FORMS END */}

      {/* BOOKMARK */}
      <section className="bg-blue">
        <div className="container p-top-1 p-bottom-3">
          <header className="my-5">
            <h2 className="font-weight-400 text-white">Bookmark</h2>
            <hr
              className="border-left mt-0"
              style={{ backgroundColor: `#fff` }}
            />
          </header>
          <div className="row" style={{ justifyContent: "center" }}>
            <img className="img-flid" style={{ height: '250px' }} src={commingSoon} alt="vomming soon k2law" />
            {/* {bData.map((bookmark, index) => {
              return (
                <div
                  className="col-lg-4 col-md-6 col-sm-12 col-12 my-2"
                  key={index}
                >
                  <div className="bookmark-wrapper">
                    <Bookmark
                      title={bookmark.title}
                      description={bookmark.description}
                    />
                  </div>
                </div>
              );
            })} */}
          </div>
        </div>
      </section>
      {/* BOOKMARK END */}

      {/* UPDATE CORNER */}
      <section>
        <div className="container-xl">
          <header className="mt-5 mb-4">
            <h2 className="font-weight-400">Update Corner</h2>
            <hr className="border-left mt-0" />
          </header>
          <div
            className="font-weight-500 d-flex justify-content-end"
            onClick={() => history.push(`/blogs`)}
            style={{ cursor: `pointer` }}
          >
            View all
          </div>
          <div className="row mb-4">
            {blogs.slice(0, 3).map((blog, index) => {
              return (
                <div
                  className="col-lg-4 col-md-6 col-sm-12 col-12 my-3"
                  key={index}
                >
                  <div className="">
                    <Link
                      to={`/blog/${blog._id}`}
                      style={{
                        textDecoration: `none`,
                        color: `#000000`,
                        cursor: `pointer`,
                      }}
                    >
                      <Blogs
                        id={blog._id}
                        img={blog.imageOrVideo}
                        title={blog.Title}
                        description={blog.Summary}
                      />
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* UPDATE CORNER EMD */}

      <Footer />
    </>
  );
}
