import React, { useRef, useState } from "react";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import OutsideClick from '../lib/outSideClick';

const ActsChapterSelect = ({ data }) => {
  const history = useHistory();
  const ref = useRef();
  const [showMenu, setShowMenu] = useState(false);
  const [showIcon, setShowIcon] = useState(false);

  const onShowMenu = () => {
    if (showMenu) {
      setShowIcon(false);
      setShowMenu(false);
    } else {
      setShowIcon(true);
      setShowMenu(true);
    }
  };

  OutsideClick(ref, () => {
    if (showMenu, showIcon) {
      setShowIcon(false);
      setShowMenu(false);
    }
  });

  return (
    <div className="select-card-wrapper">
      <div
        className="card border-0 card-shadow border-20"
        onClick={() => onShowMenu()}
        style={{ cursor: `pointer` }}
        ref={ref}
      >
        <div className="card-body">
          <div className="judgements-card">
            <h5 className="card-title font-weight-600 mb-0 mr-0 text-center">
              {data.startSection}-{data.endSection}
            </h5>
            <div className="mx-3">
              <div className="vertical-line-1"></div>
              <div className="vertical-line-2"></div>
            </div>
            <h6
              className="mb-0"
              style={{
                display: `-webkit-box`,
                WebkitLineClamp: `1`,
                WebkitBoxOrient: `vertical`,
                overflow: `hidden`,
                textOverflow: `ellipsis`,
                maxWidth: `20ch`,
              }}
            >
              {data.chapterTitle}
            </h6>
            <div className="ml-auto">
              {!showIcon && <FontAwesomeIcon icon={faChevronDown} />}
              {showIcon && <FontAwesomeIcon icon={faChevronUp} />}
            </div>
          </div>
        </div>
      </div>
      {showMenu && (
        <div className="select-card-items-wrapper">
          {data.sections.sort((a, b) => ((a?.sectionNumberId) > (b?.sectionNumberId || b?.sectionNumberId)) ? 1 : -1).map((actsSections, index) => {
            return (
              <div
                className="card mb-2 border-0 card-shadow border-20"
                onClick={() =>
                  history.push(`/laws-details/${actsSections?._id}`)
                }
                key={index}
                style={{ cursor: `pointer` }}
              >
                <div className="select-card-body">
                  <div className="judgements-card">
                    <h6 className="card-title font-weight-600 mb-0 ml-3 text-center">
                      {actsSections?.en?.sectionNumber}
                    </h6>
                    <div className="mx-4">
                      <div className="vertical-line-1"></div>
                      <div className="vertical-line-2"></div>
                    </div>
                    <h6 className="mb-0">{actsSections?.en?.sectionTitle}</h6>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ActsChapterSelect;
