import React, { useCallback, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import blogHeroImg from "../images/hero-images/forms.png";
import PrimaryHero from "../components/PrimaryHero";
import SmallCard from "../components/SmallCard";
import Border from "../components/Border";
import axios from "axios";
import apis from "../config/apis";
import headers from "../config/header";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export default function Forms() {
    const [formData, setFormData] = useState([]);
    const [dropdownOpen, setOpen] = useState(false);

    const toggle = () => setOpen(!dropdownOpen);



    const fatchBlogs = useCallback(() => {
        axios
            .get(apis + "admin/getTypesOfForm", headers)
            .then((res) => {
                setFormData(res?.data?.data);
                console.log(res?.data?.data, 'form data');
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        fatchBlogs();
    }, [fatchBlogs]);
    return (
        <>
            <Navbar />
            <PrimaryHero title="Forms" paragraph="Lorem Ipsum is simply dummy text of the printing industry." heroImg={blogHeroImg} />
            <Border />
            <section className="forms">
                <div className="container">
                    {/*<div className="form_filters">*/}
                    {/*    <div className="search_box">*/}
                    {/*        <Autocomplete*/}
                    {/*            // options={searchData}*/}
                    {/*            onChange={(ev, selectedProduct) => console.log(selectedProduct)}*/}
                    {/*            getOptionLabel={(option) => option.laws.en.lawTitle}*/}
                    {/*            renderInput={(params) => (*/}
                    {/*                <TextField*/}
                    {/*                    {...params}*/}
                    {/*                    label="Search Acts"*/}
                    {/*                    className="search-input-header"*/}
                    {/*                    style={{ borderRadius: `50px` }}*/}
                    {/*                    // disableClearable*/}
                    {/*                    margin="normal"*/}
                    {/*                    variant="outlined"*/}
                    {/*                    InputProps={{ ...params.InputProps, type: "search" }}*/}
                    {/*                // onChange={(e) => fatchSearchData(e.target.value)}*/}
                    {/*                />*/}
                    {/*            )}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*    <div className="form_drop">*/}
                    {/*        <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>*/}
                    {/*            <DropdownToggle caret>*/}
                    {/*                Gujarat*/}
                    {/*            </DropdownToggle>*/}
                    {/*            <DropdownMenu>*/}
                    {/*                <DropdownItem>Mumbai</DropdownItem>*/}
                    {/*            </DropdownMenu>*/}
                    {/*        </ButtonDropdown>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="row my-4">
                        {formData.length < 1
                            ? "No Data found :("
                            : formData.map((list, index) => {
                                return (
                                    <div
                                        className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 my-2"
                                        key={index}
                                    >
                                        <SmallCard
                                            heading={index + 1}
                                            title={list.FormName}
                                            id={`/form-details/${list._id}`}
                                        />
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}
