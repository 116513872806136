import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Markup } from "interweave";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import apis from "../config/apis";
import cardBackground from "../images/bg-1.png";
import ShowMoreText from "react-show-more-text";

const ActsSlider = () => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500,
    rows: 1,
    slidesPerRow: 2,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          slidesPerRow: 1,
          centerMode: true,
          infinite: true,
          centerPadding: "60px",
          speed: 500,
          className: "center",
          arrows: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          slidesPerRow: 1,
          centerMode: true,
          infinite: true,
          centerPadding: "60px",
          speed: 500,
          className: "center",
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          slidesPerRow: 1,
          centerMode: true,
          infinite: true,
          centerPadding: "60px",
          speed: 500,
          className: "center",
          arrows: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          slidesPerRow: 1,
          centerMode: true,
          infinite: true,
          centerPadding: "60px",
          speed: 500,
          className: "center",
          arrows: false,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          slidesPerRow: 1,
          centerMode: true,
          infinite: true,
          centerPadding: "60px",
          speed: 500,
          className: "center",
          arrows: false,
        },
      },
    ],
  };

  const [data, setData] = useState([]);

  const fatchLaws = useCallback(() => {
    axios
      .get(apis + "admin/getLaws")
      .then((res) => {
        setData(res?.data?.data);
        console.log(res?.data?.data, 'getLaws');
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    fatchLaws();
  }, [fatchLaws]);

  return (
    <div>
      <Slider {...settings}>
        {data.slice(0, 10).map((law) => {
          return (
            <div className="card-wrapper" key={law._id}>
              <div
                className="card border-0 card-shadow border-20"
                style={{
                  backgroundImage: `url(${cardBackground})`,
                  backgroundSize: `cover`,
                  height: `auto`,
                }}
              >
                <div className="card-body p-75" id={law._id} style={{ height: '250px' }}>
                  <Link
                    to={`/acts/${law._id}`}
                    style={{
                      textDecoration: `none`,
                      color: `#000000`,
                      cursor: `pointer`,
                      display: `grid`,
                      alignItems: `end`
                    }}
                  >
                    <h5 className="card-title font-weight-600">
                      {law.en.lawTitle}
                    </h5>
                    <div
                      className="card-text font-weight-400"
                      style={{
                        display: `-webkit-box`,
                        WebkitLineClamp: `6`,
                        WebkitBoxOrient: `vertical`,
                        overflow: `hidden`,
                      }}
                    >
                      <ShowMoreText
                        lines={1}
                        more="Show more"
                        less="Show less"
                        expanded={false}
                        width={400}
                        anchorClass="more-anchor"
                      >
                        <Markup content={law.en.lawSummary} />
                      </ShowMoreText>
                    </div>
                    <div className="color-group mt-4 mb-3">
                      <div className="color-item-1"></div>
                      <div className="color-item-2"></div>
                    </div>
                    <div className="card-flex mb-0" style={{ alignItems: "baseline" }}>
                      {/* {role} */}
                      {/* <div>
                        <p className="card-title font-weight-600">{law.tags}</p>
                      </div> */}
                      <span className="bookmark-icon ml-auto">
                        <FontAwesomeIcon icon={faBookmark} />
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default ActsSlider;
