import React, { useCallback, useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Border from "../components/Border";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Pagination from "@material-ui/lab/Pagination";
import heroLaws from "../images/hero-images/laws.png";
import PrimaryCard from "../components/PrimaryCard";
import axios from "axios";
import apis from "../config/apis";
import headers from "../config/header";

export default function Laws() {
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  const userData = JSON.parse(localStorage.getItem("k2Laws"));

  const fatchSearchData = (e) => {
    if (userData === null) {
      const body = {
        user_id: "",
      };
      axios
        .post(apis + `admin/searchLaw?string=${e}`, body, headers)
        .then((res) => {
          setSearchData(res?.data?.data);
          setData(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const body = {
        user_id: userData._id,
      };
      axios
        .post(apis + `admin/searchLaw?string=${e}`, body, headers)
        .then((res) => {
          setSearchData(res?.data?.data);
          setData(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
    lawsData(value);
  };

  const lawsData = useCallback((pageNum) => {
    const config = {
      params: {
        page: pageNum,
        size: 12,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (userData === null) {
      const body = {
        user_id: '',
      };
      axios
        .post(apis + "admin/getLawsByPage", body, config)
        .then((res) => {
          setData(res?.data?.data?.laws);
          console.log(res?.data?.data?.laws);
          setCount(res?.data?.data?.totalPages);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const body = {
        user_id: userData._id,
      };
      axios
      .post(apis + "admin/getLawsByPage", body, config)
      .then((res) => {
        setData(res?.data?.data?.laws);
        console.log(res?.data?.data?.laws);
        setCount(res?.data?.data?.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }, []);

  useEffect(() => {
    lawsData();
  }, [lawsData]);

  console.log(data, '---data---')

  return (
    <>
      <Navbar />

      <Hero value="Laws" img={heroLaws} />

      <Border />

      {/*<section>*/}
      {/*  <div className="container">*/}
      {/*    <div className="row">*/}
      {/*      <div className="col-lg-4 col-md-6 col-sm-12 col-12">*/}
      {/*        <Autocomplete*/}
      {/*          options={searchData}*/}
      {/*          onChange={(ev, selectedProduct) => console.log(selectedProduct)}*/}
      {/*          getOptionLabel={(option) => option?.en?.lawTitle}*/}
      {/*          renderInput={(params) => (*/}
      {/*            <TextField*/}
      {/*              {...params}*/}
      {/*              label="Search Acts"*/}
      {/*              className="search-input-header"*/}
      {/*              style={{ borderRadius: `50px` }}*/}
      {/*              // disableClearable*/}
      {/*              margin="normal"*/}
      {/*              variant="outlined"*/}
      {/*              InputProps={{ ...params.InputProps, type: "search" }}*/}
      {/*              onChange={(e) => fatchSearchData(e.target.value)}*/}
      {/*            />*/}
      {/*          )}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}

      <section>
        <div className="container">
          <div className="row my-4">
            {data.map((law) => {
              return (
                <div
                  className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 my-2"
                  key={law._id}
                >
                  <PrimaryCard
                    id={law._id}
                    title={law.en.lawTitle}
                    description={law.en.lawSummary}
                    role={law.tags}
                    isBookMark={law.isBookMark}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section>
        <div className="container d-flex justify-content-center mb-3">
          <Pagination count={count} page={page} onChange={handleChange} />
        </div>
      </section>

      <Footer />
    </>
  );
}
