import React, { useCallback, useEffect, useState } from "react";
import Logo from "../images/helplaw-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faComments } from "@fortawesome/free-solid-svg-icons";
import { getRandomColor, createImageFromInitials } from "./Utils";
import { useHistory } from "react-router";
import { Link, NavLink } from "react-router-dom";

export default function Navbar() {
  const history = useHistory();
  const [isLogin, setIsLogin] = useState(false);
  const [name, setName] = useState("");
  const userData = JSON.parse(localStorage.getItem("k2Laws"));

  let imgSrc = "";

  const checkLogin = useCallback(() => {
    if (userData) {
      setIsLogin(true);
      setName(userData.name);
    } else {
      setIsLogin(false);
      setName("");
    }
  }, [userData]);

  const onLogout = () => {
    localStorage.removeItem("k2Laws");
    window.location.reload();
    checkLogin();
  };

  useEffect(() => {
    checkLogin();
  }, [checkLogin]);

  return (
    <>
      <div className="help-chat">
        <Link
          href="#"
          className="fixed-bottom"
          style={{
            backgroundColor: `#102037`,
            color: `#ffffff`,
            height: `55px`,
            width: `55px`,
            position: `fixed`,
            bottom: `15px`,
            right: `15px`,
            marginLeft: `auto`,
            fontSize: `26px`,
            textAlign: `center`,
            borderRadius: `50%`,
            lineHeight: `53px`,
            border: `2px solid #ffffff`,
          }}
        >
          <FontAwesomeIcon icon={faComments} />
        </Link>
      </div>

      <nav className="navbar navbar-expand-lg navbar-light nav-shadow bg-white fixed-top">
        <Link className="navbar-brand ml-5" to="/">
          <img className="img-fluid" alt="logo" src={Logo} />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="collapse navbar-collapse mr-5"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <NavLink
                activeClassName="nave_active"
                className="nav-link nav-link-hover font-weight-400 font-color"
                to="/"
                exact
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                activeClassName="nave_active"
                className="nav-link nav-link-hover font-weight-400 font-color"
                to="/about"
              >
                About Us
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                activeClassName="nave_active"
                className="nav-link nav-link-hover font-weight-400 font-color"
                to="/laws"
              >
                Act
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink
                activeClassName="nave_active"
                className="nav-link nav-link-hover font-weight-400 font-color"
                to="/emergency"
              >
                Emergency
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink
                activeClassName="nave_active"
                className="nav-link nav-link-hover font-weight-400 font-color"
                to="/forms"
              >
                Forms
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink
                activeClassName="nave_active"
                className="nav-link nav-link-hover font-weight-400 font-color"
                to="/your-bookmark"
              >
                Bookmark
              </NavLink>
            </li> */}
            {/* <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle font-color"
                href="/"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Language
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="/">
                  English
                </a>
                <a className="dropdown-item" href="/">
                  Hindi
                </a>
                <a className="dropdown-item" href="/">
                  Gujarati
                </a>
                <a className="dropdown-item" href="/">
                  Marathi
                </a>
              </div>
            </li> */}
            {/* <li className="nav-item align-items-center">
              <div className="sbox">
                <form className="search-form">
                  <input
                    className="form-control search-bar"
                    placeholder="Search..."
                    type="text"
                  />
                </form>
              </div>
            </li> */}
            {isLogin ? (
              <>
                {/* <li className="nav-item align-items-center">
                  <a className="nav-link" href="/">
                    <i className="far noti-icon fa-bell"></i>
                    <FontAwesomeIcon icon={faBell} />
                  </a>
                </li> */}
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      className="img-fluid"
                      id="preview"
                      src={
                        imgSrc.length <= 0
                          ? createImageFromInitials(500, name, getRandomColor())
                          : imgSrc
                      }
                      alt="profile-pic"
                      style={{
                        height: `40px`,
                        borderRadius: `50%`,
                      }}
                    />
                  </a>
                  <div
                    className="dropdown-menu"
                    style={{ left: `-75px` }}
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <Link className="dropdown-item" href="#">
                      Profile
                    </Link>
                    <Link
                      className="dropdown-item"
                      onClick={() => onLogout()}
                      style={{ cursor: `pointer` }}
                    >
                      Log Out
                    </Link>
                  </div>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    onClick={() => history.push(`/register`)}
                  >
                    <button className="secondary-btn my-2">Login</button>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    onClick={() => history.push(`/register`)}
                  >
                    <button className="secondary-btn my-2">Sign Up</button>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </nav>
    </>
  );
}
