import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import PrimaryCard from "../components/PrimaryCard";
import Data from "../json/lawsData.json";
import Hero from "../components/Hero";
import Border from "../components/Border";
import heroBookmark from "../images/hero-images/your-bookmark.png";

export default function yourBookmark() {
  return (
    <>
      <Navbar />

      <Hero value="Your Bookmark" img={heroBookmark} />

      <Border />

      <section>
        <div className="container">
          <div className="row my-4">
            {Data.map((law) => {
              return (
                <div className="col-lg-3 col-md-6 col-sm-12 col-12 my-3">
                  <PrimaryCard
                    key={law.id}
                    title={law.title}
                    description={law.description}
                    role={law.role}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
