import React, { useCallback, useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Border from "../components/Border";
import BigCard from "../components/BigCard";
import CardInShort from "../components/CardInShort";
import heroJudgementsDetails from "../images/hero-images/judgements-details.png";
import { Markup } from "interweave";
import { useParams } from "react-router-dom";
import axios from "axios";
import apis from "../config/apis";
import headers from "../config/header";

const initial = {
  en: {
    judgementSummary: "",
  },
};

export default function JudgementsDetails() {
  const id = useParams();
  const [data, setData] = useState(initial);

  const fatchJudgements = useCallback(() => {
    const body = {
      _id: id.id,
    };
    axios
      .post(apis + "admin/findJudgementById", body, headers)
      .then((res) => {
        setData(res?.data?.data);
        console.log(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    fatchJudgements();
  }, [fatchJudgements]);

  return (
    <>
      <Navbar />

      <Hero value="Judgements Details" img={heroJudgementsDetails} />

      <Border />

      <section>
        <div className="container">
          <div className="row mt-4 mb-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 my-2">
              <BigCard
                id={data._id}
                heading={data.en.judgementYear}
                title={data.en.judgementTitle}
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="container">
          <header>
            <h4 class="font-weight-600">Summaryy</h4>
          </header>
          <p class="font-weight-400">
            <Markup content={data.en.judgementSummary} />
          </p>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-4 mb-5">
              <CardInShort
                actNumber="In Short"
                actInShortDescription={data.en.judgementInShort}
                id={data._id}
              />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
